import axios from 'axios';
import { ACCESS_TOKEN, API_ENDPOINTS } from '../utils/constants';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { useAppSelector } from '../hooks/redux';
import { authDestroy } from '../utils/authentication';

interface CrawlAction extends AnyAction {
  payload: Promise<void>;
}

const collect = (in_params: any, datastreams: any, dispatch: any, user: any): ThunkAction<Promise<void>, any, any, CrawlAction> => {
  return async function collectThunk() {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    datastreams?.map((stream: any) => {
      try {
        const runCrawl = async () => {
          // params['stream'] = stream;
          const params = {
            params: {
              ...in_params,
              stream: stream,
            },
            uid: user?.id,
            org_id: user?.org_id,
          };
          console.log('params', params);
          const response = await axios.post(`${API_ENDPOINTS.KARLA_FLASK_API}/streams/collect`, params, {
            headers: {
              Authorization: `Bearer ${ACCESS_TOKEN}`,
            },
          });

          if (response?.data && response?.data?.type === 'success') {
            // console.log('response', response);
            dispatch({ type: 'DISPLAY_WARNING', payload: { reason: 'Enqued' } });
          }
        };

        runCrawl();
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          // Handling 401 error
          authDestroy();
        } else {
          dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error } });
        }
      }
    });

    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };
};

export default collect;
