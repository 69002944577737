import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../hooks/redux';
import getDatastream from '../api/get-datastream';
import BucketLink from '../components/datastreams/BucketLink';
import LoadingBackdrop from '../components/loading/LoadingBackdrop';
import getOrganizations from '../api/get-organizations';
import DatastreamOptions from '../components/datastreams/DatastreamOptions';
import getDatastreams from '../api/get-datastreams';
import { Divider } from '@mui/material';

interface Props {
  org_id: string;
  datastream_id: string;
}

const Datastream = ({ org_id, datastream_id }: Props) => {
  const dispatch = useAppDispatch();
  const datastream = useAppSelector((state) => state.datastream);
  const datastreams = useAppSelector((state) => state.datastreams);
  const organization = useAppSelector((state) => state.organization);
  const organizations = useAppSelector((state) => state.organizations);
  const loading = useAppSelector((state) => state.loading);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (datastream_id && org_id) {
      const getDatastreamThunk = getDatastream({ datastream: datastream_id, org_id: org_id }, dispatch, user);

      dispatch(getDatastreamThunk);
    }
    if (!organizations) {
      const getOrganizationsThunk = getOrganizations({ org_id: org_id }, dispatch, user);
      dispatch(getOrganizationsThunk);
    }

    if (!datastreams && datastream_id) {
      const getDatastreamsThunk = getDatastreams({ organization_id: org_id }, dispatch, user);
      dispatch(getDatastreamsThunk);
    }
  }, [datastream_id, org_id]);

  useEffect(() => {
    if (datastreams !== false && datastream === false) {
      dispatch({ type: 'SET_DATASTREAM', payload: datastreams.find((ds: any) => ds.id === datastream_id) });
    }

    if (organizations !== false && organization === false) {
      dispatch({ type: 'SET_ORGANIZATION', payload: organizations.find((org: any) => org.id === org_id) });
    }
  }, [datastreams, organizations]);

  useEffect(() => {
    if (!organization) {
      if (organizations) {
        dispatch({ type: 'SET_ORGANIZATION', payload: organizations.find((org: any) => org.id === org_id) });
      }
    }
  }, [organizations]);

  return (
    <>
      <LoadingBackdrop open={loading} />
      <h2>{'Datastream: ' + datastream_id} </h2>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p>{'Bucket link. See the CSV files and training files in google cloud here'}</p>
        <BucketLink />
      </div>
      <Divider />
      <DatastreamOptions />
    </>
  );
};

export default Datastream;
