import axios from 'axios';
import { ACCESS_TOKEN, API_ENDPOINTS } from '../utils/constants';

const updateOrganization = async (user: any, in_params: any): Promise<any | string> => {
  const url = `${API_ENDPOINTS.KARLA_FLASK_API}/karla/operations/organization/update`;

  try {
    const params = {
      params: in_params,
      uid: user?.id,
      org_id: user?.org_id,
    };

    const response = await axios.post(url, params, {
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
      },
    });

    console.log('Update response:', response);

    if (response.status === 200) {
      return response.data.response; // Assuming 'response' contains the organization data
    } else {
      return `Error: ${response.data.reason || 'Unknown error'}`;
    }
  } catch (error) {
    // Handle network errors or unexpected responses
    if (axios.isAxiosError(error) && error.response) {
      return `Error: ${error.response.data.reason || 'Unknown error'}`;
    }
    return `Error: ${error instanceof Error ? error.message : 'Unknown error'}`;
  }
};

export default updateOrganization;
