import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../hooks/redux';
import getModel from '../api/get-model';
import TrainModel from '../components/models/TrainModel';
import LoadingBackdrop from '../components/loading/LoadingBackdrop';
import getOrganizations from '../api/get-organizations';

interface Props {
  org_id: string;
  model_id: string;
}

const Model = ({ org_id, model_id }: Props) => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector((state) => state.organization);
  const organizations = useAppSelector((state) => state.organizations);
  const model = useAppSelector((state) => state.model);
  const loading = useAppSelector((state) => state.loading);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (model_id && org_id) {
      const getModelThunk = getModel({ model: model_id, organization_id: org_id }, dispatch, user);

      dispatch(getModelThunk);
    }
    if (!organizations) {
      const getOrganizationsThunk = getOrganizations({ org_id: org_id }, dispatch, user);
      dispatch(getOrganizationsThunk);
    }
  }, [model_id, org_id]);

  useEffect(() => {
    if (!organization) {
      if (organizations) {
        dispatch({ type: 'SET_ORGANIZATION', payload: organizations.find((org: any) => org.id === org_id) });
      }
    }
  }, [organizations]);
  return (
    <>
      <LoadingBackdrop open={loading} />
      <TrainModel />
    </>
  );
};

export default Model;
