import { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import trainModel from '../../api/train-model';
import PrimaryButton from '../button/PrimaryButton';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import SelectModel from './SelectModel';
import { ACCESS_TOKEN, API_ENDPOINTS } from '../../utils/constants';
import axios from 'axios';

const TrainModel = () => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector((state) => state.organization);
  const model = useAppSelector((state) => state.model);
  const user = useAppSelector((state) => state.user);

  const [selectedTemperature, setSelectedTemperature] = useState(0);

  useEffect(() => {
    if (model && model.setup && model.setup.temperature !== undefined) {
      setSelectedTemperature(model.setup.temperature);
    }
  }, [model]);

  const handleApplyTemperature = async () => {
    try {
      const params = {
        uid: user?.id,
        org_id: user?.org_id,
        model: {
          id: model.id,
          temperature: selectedTemperature,
        },
      };
      const response = await axios.post(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/model/update`, params, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });

      if (response.status === 200) {
        console.log('Temperature applied successfully:', response.data.model.setup.temperature);
      } else {
        console.error('Failed to apply temperature:', response.data.reason);
      }
    } catch (error) {
      console.error('Error applying temperature:', error);
    }
  };

  const handleTrainModel = () => {
    const trainModelThunk = trainModel(
      {
        model: model,
        uid: organization?.id,
        manually_triggered_embedding: true,
      },
      dispatch,
      user,
    );
    dispatch(trainModelThunk);
  };

  return (
    <>
      <h1 style={{ display: 'flex', justifyContent: 'space-around' }}>
        Model: {model.name} ({model.id})
      </h1>

      {/* temperature selector + apply temperature button */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '15px', gap: '10px' }}>
        <FormControl style={{ minWidth: 150 }}>
          <InputLabel>Temperature</InputLabel>
          <Select value={selectedTemperature} onChange={(e) => setSelectedTemperature(Number(e.target.value))}>
            <MenuItem value={0.0}>0.0 (Deterministic)</MenuItem>
            <MenuItem value={0.2}>0.2</MenuItem>
            <MenuItem value={0.5}>0.5</MenuItem>
            <MenuItem value={0.7}>0.7 (Balanced)</MenuItem>
            <MenuItem value={1.0}>1.0 (More Creative)</MenuItem>
            <MenuItem value={1.5}>1.5 (Highly Creative)</MenuItem>
            <MenuItem value={2.0}>2.0 (Maximum Randomness)</MenuItem>
          </Select>
        </FormControl>

        <PrimaryButton handleClick={handleApplyTemperature} text="Apply Temperature" />
      </div>

      <PrimaryButton handleClick={handleTrainModel} text="Train model" />
      <SelectModel />
    </>
  );
};

export default TrainModel;
