import { Autocomplete, Box, Checkbox, Divider, FormControlLabel, FormGroup, TextField } from '@mui/material';
import React from 'react';
import PrimaryButton from '../button/PrimaryButton';
import axios, { AxiosError } from 'axios';
import { API_ENDPOINTS } from '../../utils/constants';
import { useAppSelector } from '../../hooks/redux';
import { useDispatch } from 'react-redux';
import countries from '../../utils/country';

interface FirstUser {
  email?: string;
  password?: string;
  first_name?: string;
  last_name?: string;
}

const AddOrganization = () => {
  const [firstUser, setFirstUser] = React.useState<FirstUser>({});
  const [newOrganization, setNewOrganization] = React.useState<any>({});
  const user = useAppSelector((state) => state.user);
  const dispatch = useDispatch();
  const gap = 45;

  const handleAddNew = async () => {
    console.log('Add new organization', firstUser, newOrganization);
    const params = {
      params: {
        user: {
          id: undefined,
          firstname: firstUser.first_name,
          lastname: firstUser.last_name,
          email: firstUser.email,
          password: firstUser.password,
        },
        organization: {
          id: undefined,
          partner: user.partner_id,
          metadata: {
            company_name: newOrganization.name,
            country: newOrganization.country,
            website_url: newOrganization.url,
            consent: newOrganization.consent,
          },
        },
      },
    };
    try {
      const response = await axios.post(`${API_ENDPOINTS.KARLA_FLASK_API}/organization/create`, params);

      if (response.data.type === 'success') {
        dispatch({ type: 'DISPLAY_SUCCESS', payload: { reason: 'Success' } });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message: string }>;
        const errorMessage = axiosError.response?.data?.message || 'An unexpected error occurred';
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: errorMessage } });
      }
    }
  };

  return (
    <>
      <div>
        <h3>{'First user settings'}</h3>
        <Divider />
        <div style={{ height: 20 }}></div>
        <div style={{ display: 'flex', gap: gap }}>
          <TextField
            name="Email"
            label="Primary email"
            variant="outlined"
            value={firstUser?.email ?? ''}
            helperText="Enter email."
            autoComplete="new-name"
            key={'new-partner-email'}
            onChange={(event) => {
              const newUser = { ...firstUser, email: event.target.value };
              setFirstUser(newUser);
            }}
          />
          <TextField
            name="Password"
            label="Password"
            type="password"
            variant="outlined"
            value={firstUser?.password ?? ''}
            helperText="Enter password."
            autoComplete="new-user-password"
            key={'password-input'}
            onChange={(event) => {
              const newUser = { ...firstUser, password: event.target.value };
              setFirstUser(newUser);
            }}
          />
        </div>
        <div style={{ display: 'flex', gap: gap }}>
          <TextField
            name="first-name"
            label="First name"
            variant="outlined"
            value={firstUser?.first_name ?? ''}
            helperText="Please enter the surname of the user."
            autoComplete="new-firstname"
            key={'new-firstname'}
            onChange={(event) => {
              const newUser = { ...firstUser, first_name: event.target.value };
              setFirstUser(newUser);
            }}
          />
          <TextField
            name="last-name"
            label="Last name"
            variant="outlined"
            value={firstUser?.last_name ?? ''}
            helperText="Enter password."
            autoComplete="new-lastname"
            key={'new-lastname'}
            onChange={(event) => {
              const newUser = { ...firstUser, last_name: event.target.value };
              setFirstUser(newUser);
            }}
          />
        </div>
        <Divider />
        <h3>{'Organization settings'}</h3>
        <div style={{ display: 'flex', gap: gap }}>
          <TextField
            name="organization-name"
            label="Organiztion Name"
            variant="outlined"
            value={newOrganization?.name ?? ''}
            helperText="Enter new organization name here."
            autoComplete="new-name"
            key={'new-organization-name'}
            onChange={(event) => {
              const newObj = { ...newOrganization, name: event.target.value };
              setNewOrganization(newObj);
            }}
          />
          <TextField
            name="organization-url"
            label="website"
            variant="outlined"
            value={newOrganization?.url ?? ''}
            helperText="Enter domain of organization here."
            autoComplete="new-url"
            key={'new-organization-url'}
            onChange={(event) => {
              const newObj = { ...newOrganization, url: event.target.value };
              setNewOrganization(newObj);
            }}
          />
        </div>
        <Autocomplete
          id="country-select"
          options={countries}
          autoHighlight
          getOptionLabel={(option: any) => option.label}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`} alt="" />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name="country"
              label={'Country of Origin'}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
          onChange={(event, value) => {
            const updatedDetails = { ...newOrganization, country: value?.label ?? '' };
            setNewOrganization(updatedDetails);
          }}
        />
      </div>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) => {
                const newOrg = { ...newOrganization, consent: event.target.checked };
                setNewOrganization(newOrg);
              }}
              required
            />
          }
          label={'I have read and I accept the terms and conditions'}
          // label="Jeg accepterer betingelserne ved brug af Karla."
        />
      </FormGroup>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <PrimaryButton text={'Add organization'} handleClick={handleAddNew} />
      </div>
    </>
  );
};

export default AddOrganization;
