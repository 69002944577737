import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import getDatastreams from '../../api/get-datastreams';
import DatastreamListItem from './DatastreamListItem';
import { ListItemText, ListSubheader } from '@mui/material';
import Modal from '../modals/Modal';
import Datastream from '../../pages/Datastream';

const DatastreamList = () => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector((state) => state.organization);
  const datastreams = useAppSelector((state) => state.datastreams);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!datastreams && organization) {
      const getDatastreamThunk = getDatastreams(
        {
          organization_id: organization?.id,
        },
        dispatch,
        user,
      );

      dispatch(getDatastreamThunk);
    }
  }, [datastreams, organization]);

  return (
    <Grid item xs={12} md={6} className="datastream-list">
      {'Datastreams:'}
      <List>
        <ListSubheader className={'operations-list-header'}>
          <ListItemText primary={'Datastream name'} className="list-header-elem" />
          <ListItemText primary={'ID'} className="list-header-elem" />
        </ListSubheader>
        {datastreams &&
          datastreams?.map((item: any) => {
            return <Modal closedChilden={<DatastreamListItem key={item.id} item={item} />} children={<Datastream org_id={organization.id} datastream_id={item.id} />} size="flex" />;
          })}
      </List>
    </Grid>
  );
};

export default DatastreamList;
