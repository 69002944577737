import { useAppSelector } from '../hooks/redux';

import LeftPanel from '../components/sections/left-panel';
import ContentPanel from '../components/sections/content-panel';
import OrganizationList from '../components/organizations/OrganizationList';
import Modal from '../components/modals/Modal';
import AddOrganization from '../components/organizations/AddOrganization';

const Customers = () => {
  const user = useAppSelector((state) => state.user);

  return (
    <div className="app customers">
      <LeftPanel />

      <ContentPanel title={'Customers'}>
        <Modal
          buttonText={'New organization'}
          children={<AddOrganization />}
          onclose={() => {
            console.log();
          }}
          title="Add new organization"
          size="tall-flex"
        />
        <OrganizationList />
      </ContentPanel>
    </div>
  );
};

export default Customers;
