import axios from 'axios';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ACCESS_TOKEN, API_ENDPOINTS } from '../utils/constants';

interface SetLoadingAction extends AnyAction {
  type: 'SET_IS_LOADING';
  payload: boolean;
}

interface DisplayErrorAction extends AnyAction {
  type: 'DISPLAY_ERROR';
  payload: string;
}

interface RemoveOrgAction extends AnyAction {
  type: 'REMOVE_ORG_SUCCESS';
  payload: string;
}

type ActionTypes = SetLoadingAction | DisplayErrorAction | RemoveOrgAction;

const deleteOrganization = (orgIdToDelete: string, userOrgId: string, userId: string): ThunkAction<Promise<void>, any, unknown, ActionTypes> => {
  return async (dispatch) => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    const params = {
      org_id: userOrgId,
      uid: userId,
      params: {
        org_id: orgIdToDelete,
      },
    };

    try {
      const url = `${API_ENDPOINTS.KARLA_FLASK_API}/karla/organization/delete`;

      const response = await axios.post(url, params, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      });

      if (response.data && response.data.status === 'success') {
        dispatch({ type: 'REMOVE_ORG_SUCCESS', payload: orgIdToDelete });
      } else {
        throw new Error(`Error from server: ${response.data.message}`);
      }
    } catch (error: unknown) {
      let errorMessage = 'An unknown error occurred';
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.message || error.message;
      }
      dispatch({ type: 'DISPLAY_ERROR', payload: errorMessage });
      console.error('Error deleting organization:', errorMessage);
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: false });
    }
  };
};

export default deleteOrganization;
